import {
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ComposedChart,
  Rectangle,
  Label,
} from "recharts";

interface NDayHighGraphProps {
  temperatureHistory: Array<number>;
  nDayHigh: Array<number>;
  dates: Array<string>;
}

const getColor = (nDayHigh: number) => {
  if (nDayHigh > 7) return "#330000";
  if (nDayHigh > 5) return "#B1442C";
  if (nDayHigh > 3) return "#E28F42";
  return "#0a3055";
};

export const NDayHighGraph = ({
  temperatureHistory,
  nDayHigh,
  dates,
}: NDayHighGraphProps) => {
  const chartData = nDayHigh.map((highDays, index) => ({
    date: dates[index],
    "Mean temperature": temperatureHistory[index],
    "N-day high": highDays,
    barColor: getColor(highDays),
  }));

  return (
    <ComposedChart
      width={600}
      height={300}
      data={chartData}
      style={{ margin: "auto" }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis yAxisId="left" orientation="left" stroke="#777777">
        <Label
          value="Temperature"
          position="insideLeft"
          fill="#777777"
          angle={-90}
          style={{ textAnchor: "middle" }}
        />
      </YAxis>
      <YAxis yAxisId="right" orientation="right" stroke="#0a3055">
        <Label
          value="N-day high"
          position="insideRight"
          fill="#0a3055"
          angle={270}
          style={{ textAnchor: "middle" }}
        />
      </YAxis>
      <Tooltip />
      <Legend />
      <Bar
        yAxisId="right"
        dataKey="N-day high"
        fill="#0a3055"
        shape={(props) => (
          <Rectangle
            {...props}
            fill={getColor(chartData[props.index as number]["N-day high"])}
            name={props.name as string}
          />
        )}
      />
      <Line
        yAxisId="left"
        stroke="#777777aa"
        type="monotone"
        dataKey="Mean temperature"
        strokeWidth={2}
      />
    </ComposedChart>
  );
};

import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Dayjs } from "dayjs";

import { LatLongCity } from "./CityPicker";
import { NDayHighGraph } from "./NDayHighGraph";

interface NDayHighDataProps {
  selectedLocation: LatLongCity;
  startDate: Dayjs;
  endDate: Dayjs;
  degC: boolean;
}

// Example url: https://archive-api.open-meteo.com/v1/archive?latitude=51.5085&longitude=-0.1257&start_date=2023-10-02&end_date=2023-10-16&daily=temperature_2m_mean&timezone=auto

const METEO_URL = `https://archive-api.open-meteo.com/v1/archive?&daily=temperature_2m_mean&timezone=auto`;

const calculateNDayHigh = (temperatures: Array<number>) => {
  const stack: Array<[number, number]> = [];
  const outputArray: Array<number> = [];

  temperatures.forEach((temperature, i) => {
    let count = 1;
    while (stack.length > 0 && stack.at(-1)[0] < temperature) {
      count += stack.at(-1)[1];
      stack.pop();
    }

    outputArray.push(count);
    stack.push([temperature, count]);
  });

  return outputArray;
};

export const NDayHighData = ({
  selectedLocation,
  startDate,
  endDate,
  degC,
}: NDayHighDataProps) => {
  const [temperatureHistory, setTemperatureHistory] = useState<Array<number>>(
    []
  );
  const [nDayHigh, setNDayHigh] = useState<Array<number>>([]);
  const [dates, setDates] = useState<Array<string>>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const url = useMemo(() => {
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    const formattedEndDate = endDate.format("YYYY-MM-DD");
    const temperatureUnit = degC ? "celsius" : "fahrenheit";
    return `${METEO_URL}&latitude=${selectedLocation.lat}&longitude=${selectedLocation.lon}&start_date=${formattedStartDate}&end_date=${formattedEndDate}&temperature_unit=${temperatureUnit}`;
  }, [selectedLocation, startDate, endDate, degC]);

  const fetchAndSetTemperatureData = useCallback(async () => {
    try {
      const response = await axios.get(url);
      const rawTemperature = response.data.daily.temperature_2m_mean;
      const rawDates = response.data.daily.time;
      const formattedTemperature = [] as Array<number>;
      const formattedDates = [] as Array<string>;

      rawTemperature.forEach((temp: number, index: number) => {
        if (temp === null) {
          return;
        }
        formattedTemperature.push(Number(temp.toFixed(0)));
        formattedDates.push(rawDates[index].substring(5));
      });

      const calculatedNDayHigh = calculateNDayHigh(formattedTemperature);

      setErrorMessage("");
      setTemperatureHistory(formattedTemperature);
      setDates(formattedDates);
      setNDayHigh(calculatedNDayHigh);
    } catch (error) {
      setErrorMessage(error.response.data.reason);
      console.error("Error fetching temperature history:", error);
    }
  }, [url]);

  useEffect(() => {
    fetchAndSetTemperatureData();
  }, [fetchAndSetTemperatureData]);

  return (
    <>
      {errorMessage && (
        <p style={{ color: "red" }}>
          Error fetching data: &quot;{errorMessage}&quot;
        </p>
      )}
      <NDayHighGraph
        temperatureHistory={temperatureHistory}
        nDayHigh={nDayHigh}
        dates={dates}
      />
    </>
  );
};

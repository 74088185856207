import { CSSProperties, useState } from "react";
import dayjs from "dayjs";

import { Card, Grid, Stack, Switch, Typography } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";

import CityPicker, { LatLongCity } from "./CityPicker";
import { NDayHighData } from "./NDayHighData";
import { DateInput } from "./DateInput";
import { Info } from "./Info";

// Start in New York
const DEFAULT_LOCATION: LatLongCity = {
  lat: 40.7127281,
  lon: -74.0060152,
  display_name: "New York",
};
const TODAY = dayjs();

const backgroundDividerStyle = {
  background: "linear-gradient(93deg,#b0432d -3%,#d7944d 106%)",
  height: "0.25rem",
  width: "100%",
  marginBottom: "30px",
  boxShadow: "#00000055 0px 2px 4px 0px",
};

const footerStyle: CSSProperties = {
  color: "white",
  margin: "20px auto",
  textAlign: "center",
};

const linkStyle: CSSProperties = {
  color: "white",
};

export const App = () => {
  const [selectedLocation, setSelectedLocation] =
    useState<LatLongCity>(DEFAULT_LOCATION);
  const [startDate, setStartDate] = useState(TODAY.subtract(28, "day"));
  const [endDate, setEndDate] = useState(TODAY.subtract(7, "day"));
  const [degC, setDegC] = useState(false);

  return (
    <StyledEngineProvider injectFirst>
      <Card style={{ maxWidth: 650, margin: "20px auto", padding: 20 }}>
        <Typography variant="h2">Flu Outbreak Prediction</Typography>
        <div style={backgroundDividerStyle} />
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <CityPicker
              onLocationSelect={setSelectedLocation}
              defaultLocation={DEFAULT_LOCATION}
            />
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>°F</Typography>
              <Switch checked={degC} onChange={() => setDegC((s) => !s)} />
              <Typography>°C</Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <DateInput
              label="Start Date"
              value={startDate}
              onChange={setStartDate}
            />
          </Grid>
          <Grid item xs={6}>
            <DateInput label="End Date" value={endDate} onChange={setEndDate} />
          </Grid>
          <Grid item xs={12}>
            <NDayHighData
              selectedLocation={selectedLocation}
              startDate={startDate}
              endDate={endDate}
              degC={degC}
            />
          </Grid>
        </Grid>
        <Info />
      </Card>
      <footer style={footerStyle}>
        Marcus Fantham |{" "}
        <a href="https://fnt.hm" target="_blank" style={linkStyle}>
          fnt.hm
        </a>
      </footer>
    </StyledEngineProvider>
  );
};

export default App;

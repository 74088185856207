import dayjs, { Dayjs } from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

interface DateInputProps {
  label: string;
  value: Dayjs;
  onChange: (date: Dayjs) => void;
}

export const DateInput = ({ label, value, onChange }: DateInputProps) => {
  const disableLast5Days = (date: Dayjs) => {
    return date.isAfter(dayjs().subtract(6, "day"));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        sx={{ width: "100%" }}
        shouldDisableDate={disableLast5Days}
      />
    </LocalizationProvider>
  );
};

import { useCallback, useState } from "react";

import { styled } from "@mui/material/styles";
import { CardActions, CardContent, Collapse, Typography } from "@mui/material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const Info = () => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded((s) => !s);
  }, []);

  return (
    <>
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            The n-day high value for a given day is defined as follows: <br />
            It represents the number of consecutive preceding days for which the
            temperature is strictly less than the given day's temperature (plus
            one to include the current day).
          </Typography>
          <Typography paragraph>
            The daily mean temperature, from{" "}
            <a href="https://open-meteo.com/" target="_blank">
              open-meteo
            </a>
            , is shown as a grey line graph.
            <br />
            The n-day high is shown as colored bars. Exceptionally high days are
            colored as follows: <br />
            <span style={{ color: "#E28F42" }}>n-day high &gt; 3: orange</span>
            <br />
            <span style={{ color: "#B1442C" }}>n-day high &gt; 5: red</span>
            <br />
            <span style={{ color: "#330000" }}>
              n-day high &gt; 7: dark red
            </span>
            <br />
            <br />
            Note open-meteo does not provide historical weather data for the
            last 5 days.
          </Typography>
        </CardContent>
      </Collapse>
    </>
  );
};

import { useEffect, useState } from "react";
import axios from "axios";
import { useDebounce } from "use-debounce";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const LOCATIONIQ_API_KEY = process.env.LOCATIONIQ_API_KEY;
const LOCATIONIQ_URL = `https://api.locationiq.com/v1/autocomplete?key=${LOCATIONIQ_API_KEY}&q=`;

export type LatLongCity = {
  lat: number;
  lon: number;
  display_name: string;
};

interface CityPickerProps {
  onLocationSelect: (latLng: LatLongCity) => void;
  defaultLocation: LatLongCity;
}

export default function CityPicker({
  onLocationSelect,
  defaultLocation,
}: CityPickerProps) {
  const [inputValue, setInputValue] = useState(defaultLocation.display_name);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(defaultLocation);

  const [debouncedInputValue] = useDebounce(inputValue, 300);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (debouncedInputValue.trim() === "") {
        setOptions([]);
        return;
      }

      try {
        const response = await axios.get(
          `${LOCATIONIQ_URL}${debouncedInputValue}`
        );
        setOptions(response.data);
      } catch (error) {
        console.error("Error fetching location suggestions:", error);
      }
    };

    fetchSuggestions();
  }, [debouncedInputValue]);

  return (
    <Autocomplete
      getOptionLabel={(option) => option.display_name}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        newValue && onLocationSelect(newValue as LatLongCity);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      inputValue={inputValue}
      renderInput={(params) => <TextField {...params} label="City" fullWidth />}
    />
  );
}
